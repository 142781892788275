.loading {
  position: relative;
  cursor: progress;
  text-shadow: none;
  color: transparent;
  opacity: 1;
  pointer-events: auto;
  transition: all 0s linear, opacity 0.1s ease;
}

.loading::before {
  position: absolute;
  content: '';
  opacity: 1 !important;
  top: 50%;
  left: 50%;
  margin: -0.64285714em 0 0 -0.64285714em;
  width: 1.28571429em;
  height: 1.28571429em;
  border-radius: 500rem;
  border: 0.2em solid rgba(0, 0, 0, 0.15);
}

.loading::after {
  position: absolute;
  content: '';
  opacity: 1 !important;
  top: 50%;
  left: 50%;
  margin: -0.64285714em 0 0 -0.64285714em;
  width: 1.28571429em;
  height: 1.28571429em;
  animation: loader-spin 0.6s linear;
  animation-iteration-count: infinite;
  border-radius: 500rem;
  border: 0.2em solid transparent;
  border-top-color: #fff;
  box-shadow: 0 0 0 1px transparent;
}

@keyframes loader-spin {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}
