@import 'src/app/modules/@theme/components/search-input/search-input.component.scss';
@import 'src/app/modules/@theme/styles/variables';

.deactivated {
  background: rgba(gray, 0.1);
}

table {
  .selected {
    background-color: rgba(blue, 0.1) !important;
  }
}

.page-item.active {
  .page-link {
    background-color: $color-primary;
    border-color: $color-primary;
  }
}

.clickableColumn {
  text-decoration: underline;
  cursor: pointer;
  font-weight: bold !important;
}
