/*Unify scrollbar*/
*::-webkit-scrollbar {
  width: 0.8125rem !important;
  height: 0.8125rem !important;
}

*::-webkit-scrollbar-thumb {
  background: #cdcdce;
  cursor: pointer;
  border-radius: 0.15625rem;
}

*::-webkit-scrollbar-track {
  background: white;
}
