:host {
  display: flex;
  align-items: center;
  background-color: #ffffff;
  padding: 4px;
  i.control-icon {
    &::before {
      font-size: 2.3rem;
    }

    &:hover {
      cursor: pointer;
    }
  }
}
